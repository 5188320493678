import { useMsal } from "@azure/msal-react";
import React from "react";
import { loginRequest } from "../authConfig";
import { Button } from "primereact/button";

export function LoginPage() {
  const { instance } = useMsal();
  return (
    <>
      <div className="triangle-image">
        <img src="https://stkisapikappaae4symbg.blob.core.windows.net/loginlayout/triangle-img.png" alt="Background Image" />
      </div>

      <div className="w-full absolute top-0 bottom-0 left-0 right-0 m-auto flex align-items-center justify-content-center h-auto flex-column">
        <div className="bg-grey w-1 logout-img">
          {" "}
          <img src="/assets/icons/logout.svg" />
        </div>

        <Button
          className="border-0 bg-transparent"
          label="Sign in"
          onClick={() => {
            instance.loginRedirect(loginRequest);
          }}>
          {" "}
        </Button>
        <div className="text-purple font-bold"></div>
      </div>
    </>
  );
}
