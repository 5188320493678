import React, { useEffect, useRef } from "react";
import "./App.css";
import { Routes, Route } from "react-router";
import { Modal, ModalRef } from "./components/modal/modal";

import { Home } from "./pages/Home";
import LandingRouter from "./pages/LandingRouter";
import ScrollToTopButton from "./components/scrollToTop/ScrollToTop";
import { AuthRoute } from "./shared/auth/AuthRoute";
import { Role } from "./shared/enums/Role";
import { InviteUserMaster } from "./pages/InviteUserMaster";
import axios from "axios";
import { ToasterComponent } from "./components/toaster/toaster";
// import { useApiClient } from "./shared/customHooks";
//import ExternalLink from "./components/externalLink/externalLink";
import ExternalApplicationEvidence from "./pages/external/ExternalApplicationEvidence";
import ExternalApplicationRequest from "./pages/external/ExternalApplicationRequest";
import ApplicationsList from "./pages/appllicationPages/ApplicationsList";
import ApplicationView from "./pages/appllicationPages/ApplicationView";
import { Footer } from "./components/footer/Footer";
import { LoginPage } from "./pages/Login";
import LogoutPage from "./pages/Logout";
import ApplicationFilesPage from "./pages/appllicationPages/ApplicationFilesPage";
import ChildPage from "./pages/appllicationPages/ChildPage";
import KindergartenPage from "./pages/appllicationPages/KindergartenPage";
import ApplicationTypePage from "./pages/appllicationPages/ApplicationTypePage";
import { SpinnerComponent } from "./components/spinner/SpinnerComponent";

function App() {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {}, []);

  return (
    <>
      <Modal cancelAction={() => console.log("closing")} confirmAction={() => console.log("confirm")} ref={modalRef} />
      <div className="app-container">
        <Routes>
          <Route>
            <Route path="/*" element={<LandingRouter />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route
              path="home"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <Home />
                </AuthRoute>
              }
            />
            <Route
              path="applications"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <ApplicationsList />
                </AuthRoute>
              }
            />
            <Route
              path="viewapplication"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <ApplicationView />
                </AuthRoute>
              }
            />
            <Route
              path="applicationfiles"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <ApplicationFilesPage />
                </AuthRoute>
              }
            />
            <Route
              path="child"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <ChildPage />
                </AuthRoute>
              }
            />
            <Route
              path="applicationtype"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <ApplicationTypePage />
                </AuthRoute>
              }
            />
            <Route
              path="kindergarten"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <KindergartenPage />
                </AuthRoute>
              }
            />
            <Route
              path="invite"
              element={
                <AuthRoute roles={[Role.admin, Role.endUser, Role.root]}>
                  <InviteUserMaster />
                </AuthRoute>
              }
            />
            <Route path="applicationevidence" element={<ExternalApplicationEvidence />} />
            <Route
              path="externalRequest"
              element={
                <>
                  <ExternalApplicationRequest />
                </>
              }
            />
          </Route>
        </Routes>
        <Footer />
        <ToasterComponent />
        <SpinnerComponent />
      </div>
      <ScrollToTopButton />
    </>
  );
}

export default App;
