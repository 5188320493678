import { FormGroup } from "../../../components/form";

export const applicationFilesForm: FormGroup = {
  type: "formGroup",

  fields: [
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      label: "Application and Evidence Upload",
    },
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "warning",
      },
      label: "Upload your application forms and all supporting information documents in the sections as indicated.",
    },
    {
      type: "field",
      fieldType: "advisory",
      key: "advisory",
    },
    {
      type: "field",
      key: "applicationFormPartOne",
      fieldType: "file",
      label: "Application Form - Section 1",
      warn: "This section is for the KIS program application form - Section 1",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
    },
    {
      type: "field",
      key: "applicationFormPartTwo",
      fieldType: "file",
      label: "Application Form - Section 2",
      warn: "This section is for the KIS program application form - Section 2",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
    },
    {
      type: "field",
      key: "evidenceDocuments",
      fieldType: "file",
      label: "Evidence Documents",
      warn: "This section is for all the Evidence Documents being used to support the application",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
    },
  ],
};
