export enum Role {
  root = "root",
  admin = "admin",
  endUser = "endUser",
  department = "department",
}

export enum RoleId {
  root = 1,
  admin = 2,
  endUser = 3,
  department = 4,
}
