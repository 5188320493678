import * as Yup from "yup";
import { FormGroup } from "../../components/form";

export const kinderForm: FormGroup = {
  type: "formGroup",
  fields: [
    {
      type: "field",
      key: "fundedKinder",
      fieldType: "search",
      label: "Kindergarten Name",
      props: {
        apiPath: "FundedKinder/search",
        placeholder: "Kindergarten Name",
        labelValue: "accountName",
        noResultsMessage: 'No entries found. Select "Other" to enter the name of your kindergarten',
      },
      validation: Yup.object().required("A response is required."),
    },
    {
      type: "field",
      fieldType: "input",
      key: "kindergartenNameOther",
      label: "Enter the Name of the Kindergarten",
      validation: Yup.string().required("A response is required"),
      hideExpression: (context, values, model) => {
        return model?.fundedKinder?.accountName !== "Other";
      },
    },
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "fieldadv",
      },
      label:
        "Enter the first few letters of your kindergarten name to search. If you cannot find the name of the kindergarten in the list, select 'Other' and the \"Enter the Name of the Kindergarten\" field will display. Enter the name of the kindergarten in that field.",
    },
    {
      type: "field",
      key: "contactName",
      fieldType: "input",
      label: "Contact Name",
      validation: Yup.string().required("A response is required").matches(RegExp("^[a-zA-Z- ']*$"), { message: "Please enter a valid contact name" }),
    },
    {
      type: "field",
      key: "contactEmail",
      fieldType: "input",
      label: "Contact Email",
      validation: Yup.string()
        .required("A response is required")
        .matches(RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$"), { message: "Please enter a valid email address" }),
    },
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "fieldadv",
      },
      label: "This will be the email used for all follow-up communication relating to the application.",
    },
    {
      type: "field",
      key: "contactNumber",
      fieldType: "input",
      label: "Contact Number",
      validation: Yup.string()
        .required("A response is required")
        .matches(
          RegExp(
            "^((04\\d{2} ?\\d{3} ?\\d{3})|(\\+614 ?\\d{4} ?\\d{4})|((\\(02\\)|\\(03\\)|\\(08\\)) ?\\d{4} ?\\d{4})|(02|03|08)\\s?\\d{4} ?\\d{4}|(\\+613 ?\\d{4} ?\\d{4}))$"
          ),
          {
            message: "Please enter a valid contact number",
          }
        ),
    },
  ],
};
