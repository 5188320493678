import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../components/header/Header";
import React from "react";
import ExternalLink from "../../components/externalLink/externalLink";
import ChildComponent from "../../components/child/ChildComponent";
import KinderComponent from "../../components/kindergarten/KindergartenComponent";
import ApplicationFiles from "../../components/applicationFiles/ApplicationFilesComponent";
import { PageHeader } from "../../components/header/PageHeader";
import ApplicationTypeComponent from "../../components/applicationType/ApplicationTypeComponent";

export default function ApplicationView() {
  const [id, setId] = useState("");
  const [searchParams] = useSearchParams();
  const headerText = [
    {
      class: "flex align-center mt-0",
      value: "View Application",
      icon: "view-app-icon mr-3 mt-0",
    },
  ];
  useEffect(() => {
    const idParam = searchParams.get("id");
    setId(idParam ?? "");
  }, [id]);

  return (
    <div className="w-full">
      <Header />
      <PageHeader headerText={headerText} icon={"icon"} />

      <div className="flex flex-column align-items-start w-full">
        <ApplicationTypeComponent id={id} disabled={true} />
        <ChildComponent id={id} disabled={true} />
        <KinderComponent id={id} disabled={true} />
        <ExternalLink linkLabel="Application and Evidence Document Upload" applicationId={id} />
        <ApplicationFiles applicationId={id} disabled={true} />
      </div>
    </div>
  );
}
