import axios from "axios";

const API_BASE_URL = `${import.meta.env.VITE_FILESCANNER}`; // Replace with your actual API base URL
export const postValidateFile = async (filename: string) => {
  return axios.get(`${API_BASE_URL}/TriggerFileValidation`, { params: { fileName: filename } });
  return; // This will include the response data, status, and other information
};

// // Create a new user in the API
// export const createUser = async (user) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/users`, user);
//     return response; // This will include the response data, status, and other information
//   } catch (error) {
//     // Handle or throw the error as needed
//     console.error("Error creating user:", error);
//     throw error;
//   }
// };
