export interface RoutesI {
  path: string;
  breadcrumb: string;
}

export const routes: RoutesI[] = [
  { path: "/home", breadcrumb: "Home" },
  { path: "/cycles", breadcrumb: "Cycles Page" },
  { path: "/editableTable", breadcrumb: "Editable table" },
  { path: "/editableTable/:id", breadcrumb: "edit Id" },
  { path: "/viewapplication", breadcrumb: "View Application" },
  { path: "/applicationtype", breadcrumb: "Application Type" },
  { path: "/applicationfiles", breadcrumb: "Application Files" },
];
