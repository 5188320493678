import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export interface CaptchaProps {
  name: string;
  label: string;
  container?: string;
  title: string;
  value: string;
  warn?: string;
}

export default function CaptchaComponent(props: CaptchaProps) {
  const { setFieldValue } = useFormikContext();

  const onChange = (value: any) => {
    setFieldValue(props.name, value);
  };

  return (
    <>
      <ReCAPTCHA sitekey="6LdjjkcqAAAAAD54naiZ5sJ5Pe8dWfZYJQVXUf_R" onChange={onChange} />

      <ErrorMessage name={props.name} render={(msg) => <div className="error">{msg}</div>} />
    </>
  );
}
