import React, { useContext, useEffect, useMemo, useState } from "react";
import { Field, FormGroup, PInput } from "../models/models";
import { useFormikContext } from "formik";
import Input from "./Input";
import CalendarComponent from "./CalendarComponent";
import CheckBoxComponent from "./CheckBoxComponent";
import DataTableComponent from "./DatatableComponent";
import DropdownComponent from "./DropdownComponent";
import EditorComponent from "./EditorComponent";
import FileUploadComponent from "./FileUploadComponent";
import * as Yup from "yup";
import { AdvisoryText } from "../../AdvisoryText";
import { FormContext } from "../context/formContext";
import CaptchaComponent from "./CaptchaComponent";
import SearchSelectComponent from "./SearchSelectComponent";
import ErrorComponent from "./ErrorComponent";

export default function FormCreatorComponent({ formBody }: { formBody: FormGroup }) {
  return (
    <FormGroupCompnent
      parentKey=""
      elementKey={formBody.key}
      label={formBody.label}
      fields={formBody.fields}
      warn={formBody.warn}
      hideExpression={formBody.hideExpression}
      test={formBody.test}
    />
  );
}

function FormGroupCompnent({
  parentKey,
  elementKey,
  label,
  warn,
  hideExpression,
  warnType,
  fields,
  test,
}: {
  parentKey: string;
  elementKey?: string;
  label?: string;
  warn?: string;
  hideExpression?: (formContext: any, values: any, model: any) => boolean;
  warnType?: "warning" | "information" | "error" | "announcement" | "fieldadv";
  fields: (Field | FormGroup | PInput)[];
  test?: [nameString: string, valueFunction: (value: any) => boolean];
}) {
  const { isSubmitting, setFieldValue, values } = useFormikContext();

  const context = useContext(FormContext);

  const [blocked, setBlocked] = useState(false);
  useEffect(() => {
    if (test == null) return;

    const [name, func] = test!;
    if (isSubmitting) {
      const pass = func(values);
      if (!pass && !blocked) {
        setFieldValue(name, false);
        setBlocked(true);
      }
    } else if (blocked) {
      const pass = func(values);
      if (pass) {
        setFieldValue(name, true);
        setBlocked(false);
      }
    }
  }, [isSubmitting, test, values, blocked]);

  const nextParentKey = useMemo(() => {
    return [parentKey, elementKey].filter((x) => x !== "" && x != null).join(".");
  }, [parentKey, elementKey]);

  const inputParentModel = useMemo(() => {
    const value = parentKey ? parentKey.split(".").reduce((prev, curr) => (prev ? prev[curr] : undefined), values as any) : values;
    return value;
  }, [nextParentKey, values]);

  const hidden = useMemo(() => {
    let isHidden = false;
    if (hideExpression != null) {
      isHidden = hideExpression(context.formContextStore, values, inputParentModel);
    }
    return isHidden;
  }, [hideExpression, values, inputParentModel, context]);

  return (
    <>
      {!hidden && (
        <>
          {(label || warn) && (
            <div className="py-2 ">
              {label && (
                <h4 className="text-purple flex align-items-center border-bottom-2">
                  <span className="header-icon p-3 mr-1 mt-0"></span>
                  <span>{label}</span>
                </h4>
              )}
              {warn && <AdvisoryText type={warnType ?? "warn"} label={warn} />}
            </div>
          )}
          {fields.map((f) => {
            switch (f.type) {
              case "formGroup":
                f as FormGroup;
                return (
                  <FormGroupCompnent
                    key={f.key}
                    parentKey={nextParentKey}
                    elementKey={f.key}
                    label={f.label}
                    fields={f.fields}
                    hideExpression={f.hideExpression}
                  />
                );
              case "field":
                f as Field;
                return (
                  <>
                    <FieldComponent
                      key={f.key}
                      parentKey={nextParentKey}
                      elementKey={f.key}
                      fieldType={f.fieldType}
                      label={f.label}
                      warn={f.warn}
                      props={f.props}
                      expressionProps={f.expressionProps}
                      hideExpression={f.hideExpression}
                    />
                    <div className={f.props?.advisorytextcss}>{f.props?.advisorytext}</div>
                  </>
                );
              case "pInput":
                f as PInput;
                return <div className={f.props?.css}>{f.label}</div>;
                break;
              // case "component":
              //   f as AnyComponent;
              //   return f.body;
            }
          })}
        </>
      )}
    </>
  );
}

function FieldComponent({
  parentKey,
  elementKey,
  fieldType,
  label,
  hideExpression,
  warn,
  props,
  expressionProps,
}: {
  parentKey: string;
  elementKey: string;
  fieldType: string;
  label?: string;
  hideExpression?: (formContext: any, values: any, model: any) => boolean;
  warn?: string;
  props: any;
  expressionProps?: { [key: string]: (formContext: any, values: any, model: any) => any };
}) {
  const { values, setFieldValue } = useFormikContext();
  const context = useContext(FormContext);

  const Input = useMemo(() => {
    return matchedInput(fieldType);
  }, [fieldType]);

  const finalKey = useMemo(() => {
    return [parentKey, elementKey].filter((x) => x !== "" && x != null).join(".");
  }, [parentKey, elementKey]);

  const inputValue = useMemo(() => {
    const value = finalKey.split(".").reduce((prev, curr) => (prev ? prev[curr] : undefined), values as any);
    return value;
  }, [finalKey, values]);

  const inputParentModel = useMemo(() => {
    const value = parentKey ? parentKey.split(".").reduce((prev, curr) => (prev ? prev[curr] : undefined), values as any) : values;
    return value;
  }, [finalKey, values]);

  const [resolvedExpressionProps, setResolvedExpressionProps] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (expressionProps != null) {
      const newProps: { [key: string]: any } = {};
      Object.keys(expressionProps).forEach((key) => {
        const res = expressionProps[key](context.formContextStore, values, inputParentModel);
        newProps[key] = res;
      });
      setResolvedExpressionProps(newProps);
    }

    if (hideExpression != null) {
      const isHidden = hideExpression(context.formContextStore, values, inputParentModel);
      // Only update the state if `isHidden` differs from `hidden`
      if (isHidden !== inputParentModel[elementKey + "Hidden"]) {
        setFieldValue(finalKey + "Hidden", isHidden);
      }
    }
  }, [expressionProps, hideExpression, values, inputParentModel, context]);

  return (
    <>
      {!inputParentModel[elementKey + "Hidden"] && (
        <div className="py-2 w-full">
          <Input
            name={finalKey}
            value={inputValue}
            warn={warn}
            label={label}
            //leave last to override any other props
            {...{ disabled: context.formContextStore.disabled, ...props, ...resolvedExpressionProps }}
          />
        </div>
      )}
    </>
  );
}

function matchedInput(fieldType: string): React.FC<any> {
  switch (fieldType) {
    case "input":
      return Input;

    case "calendar":
      return CalendarComponent;

    case "checkbox":
      return CheckBoxComponent;

    case "datatable":
      return DataTableComponent;

    case "dropdown":
      return DropdownComponent;

    case "editor":
      return EditorComponent;

    case "file":
      return FileUploadComponent;
    case "captcha":
      return CaptchaComponent;
    case "advisory":
      return AdvisoryText;
    case "search":
      return SearchSelectComponent;
    case "error":
      return ErrorComponent;
    default:
      return function Default() {
        return <></>;
      };
  }
}

export function getInitalValuesAndValidationSchema(FormBody: FormGroup): [any, Yup.ObjectSchema<any>] {
  let [model, yup] = createInitialValues({}, {}, FormBody.fields, true);

  if (FormBody.key != null) {
    model = { [FormBody.key]: model };
    yup = Yup.object({ [FormBody.key]: yup });
  }
  return [model, yup];
}
function createInitialValues(currModel: any, currYup: any, currFields: (Field | FormGroup | PInput)[], doYup: boolean): [any, Yup.ObjectSchema<any>] {
  currFields.forEach((f) => {
    if (f.type == "field") {
      currModel[f.key] = getInitialValueForField(f);
      if (f.validation) {
        currYup[f.key] = f.validation;
        currYup[f.key] = getValidationForField(f).when(f.key + "Hidden", {
          is: false,
          then: () => f.validation,
          otherwise: () => Yup.mixed().notRequired(),
        } as any);
        currModel[f.key + "Hidden"] = false;
        currYup[f.key + "Hidden"] = Yup.boolean().required();
      }
    } else if (f.type != "pInput") {
      if (f.key != null) {
        const res = createInitialValues({}, {}, f.fields, true);
        currModel[f.key] = res[0];
        currYup[f.key] = res[1];
      } else {
        createInitialValues(currModel, currYup, f.fields, false);
      }
    }
  });
  //if FieldGroup is its own object make Yup.object, else return to higher level
  if (doYup) currYup = Yup.object(currYup);
  return [currModel, currYup];
}

function getInitialValueForField(field: Field): any {
  switch (field.fieldType) {
    case "input":
      return field.defaultValue ?? "";

    case "calendar":
      return field.defaultValue ?? null;

    case "checkbox":
      return field.defaultValue ?? false;

    case "datatable":
      return field.defaultValue ?? [];

    case "dropdown":
      return field.defaultValue ?? null;

    case "editor":
      return field.defaultValue ?? "";

    case "file":
      return field.defaultValue ?? { files: [] };

    case "search":
      return field.defaultValue ?? null;
    case "error":
      return true;
    default:
      return null;
  }
}

function getValidationForField(field: Field): Yup.Schema<any> {
  switch (field.fieldType) {
    case "input":
      return Yup.string();
      break;

    case "calendar":
      return Yup.date();
      break;

    case "checkbox":
      return Yup.boolean();
      break;

    case "datatable":
      return Yup.array();
      break;

    case "dropdown":
      return Yup.mixed(); // You can adjust this based on your dropdown's type
      break;

    case "editor":
      return Yup.string();
      break;

    case "file":
      return Yup.object().shape({
        files: Yup.array(),
      });
      break;

    case "search":
      return Yup.string();
      break;

    default:
      return Yup.mixed(); // Default case for unsupported field types
      break;
  }
}
