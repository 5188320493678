import { Navigate } from "react-router-dom";
import { AccessDenied } from "../../pages/AccessDenied";
import { Role } from "../enums/Role";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

/**
 * A wrapper around the element which checks if the user is authenticated
 * If authenticated, renders the passed element
 * If not authenticated, redirects the user to Login page.
 */
export const AuthRoute = ({ children, roles }: { children: JSX.Element; roles: Array<Role> }) => {
  const { inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [exteRole, setexteRole] = useState<any>("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const role = account != undefined && account?.idTokenClaims != undefined && account?.idTokenClaims["extension_Roles"];
    setexteRole(role);
  }, []);

  const userHasRequiredRole = roles.includes(exteRole) ? true : false;

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />;
  }

  return children;
};
