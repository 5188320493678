import React from "react";
import Table from "../../components/table/Table";
import { useApiClient } from "../../shared/customHooks";
import { applicationsListTable } from "../../shared/tableObjects/applicationsListTable";
import { Header } from "../../components/header/Header";
import { PageHeader } from "../../components/header/PageHeader";

export default function ApplicationsList() {
  const apiClient = useApiClient("Application");
  const headerText = [
    {
      class: "p-0 m-0 flex align-center",
      value: "List of Applications",
      icon: "list-icon",
    },
  ];
  return (
    <>
      <Header />
      <PageHeader headerText={headerText} hideBreadcrumbs={true} icon={"icon"}></PageHeader>
      <div className="list-table">
        {" "}
        <Table apiClient={apiClient} label={""} columns={applicationsListTable} />
      </div>
    </>
  );
}
