import React from "react";
import { Field, ErrorMessage } from "formik";
import { InputText, InputTextProps } from "primereact/inputtext";

interface InputProps extends InputTextProps {
  label: string;
  name: string;
  value: any;
}
export default function Input(props: InputProps) {
  const { name, label, ...rest } = props;
  return (
    <div className="flex flex-column w-full">
      {!props?.hidden && (
        <label htmlFor={name} id={name.split(".").pop()}>
          {" "}
          {label}
        </label>
      )}
      <Field alt={name} name={name} {...rest} as={InputText} />
      <ErrorMessage name={name ?? ""} render={(msg) => <div className="error">{msg}</div>} />
    </div>
  );
}
