import React, { useState } from "react";
import { useApiClient } from "../../shared/customHooks";
import FormContainer from "../form/FormContainer";
import { applicationFilesForm } from "../../shared/formObjects/application/applicationFilesForm";
import ComponentHeader, { ComponentHeadeProps } from "../componentHeader/componentHeader";

export interface applicationFilesProps {
  applicationId: string;
  disabled?: boolean;
  cancelPath?: string;
}

export default function ApplicationFilesComponent({ applicationId, disabled = false }: applicationFilesProps) {
  const api = useApiClient("ApplicationFiles");
  const [headerData, setHeaderData] = useState<ComponentHeadeProps>({});
  const [formContext, setFormContext] = useState<any>({});

  function getHeaderData(data: any) {
    const headerData: ComponentHeadeProps = {
      dateCreated: data?.dateCreated,
      userCreated: data?.userCreated,
      showEdit: disabled,
      editPath: `/applicationfiles?id=${applicationId}`,
      showBack: !disabled,
      backPath: `/viewapplication?id=${applicationId}`,
    };
    setHeaderData(headerData);
    setFormContext({ uuid: data?.fileUuid });
  }

  return (
    <>
      <ComponentHeader {...headerData} label="Application and Evidence Upload" />
      <FormContainer
        apiClient={api}
        id={applicationId}
        formBody={applicationFilesForm}
        formTitle=""
        disabled={disabled}
        getInitialValues={getHeaderData}
        cancelPath={`/viewapplication?id=${applicationId}`}
        submitRoute={`/viewapplication?id=${applicationId}`}
        formContext={formContext}
      />
    </>
  );
}
