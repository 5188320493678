import React from "react";

interface advisoryText {
  type: string;
  label: string;
}

export function AdvisoryText({ type, label }: advisoryText) {
  return (
    <>
      <div className={" mr-5 text-left " + type}>{label}</div>
    </>
  );
}
