import React from "react";
import { ErrorMessage, Field } from "formik";
import { Dropdown } from "primereact/dropdown";

function DropdownComponent(props: any) {
  const { name, label, ...rest } = props;
  return (
    <div className="flex flex-column w-full">
      <label htmlFor={name}> {label}</label>
      <Field alt={name} name={name} {...rest} as={Dropdown} className="border-noround" />
      <ErrorMessage name={name} render={(msg) => <div className="error">{msg}</div>} />
    </div>
  );
}
export default DropdownComponent;
