import React from "react";
import { ErrorMessage, Field } from "formik";
import { Editor } from "primereact/editor";

function EditorComponent(props: any) {
  const { name, label, ...rest } = props;
  return (
    <div className="flex flex-column w-full">
      <label htmlFor={name}> {label}</label>
      <Field alt={name} name={name} {...rest} as={Editor} />
      <ErrorMessage name={name} render={(msg) => <div className="error">{msg}</div>} />
    </div>
  );
}
export default EditorComponent;
