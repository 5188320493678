import { useEffect, useRef, useState } from "react";

/**
 * Custom hook that triggers a callback when the provided value changes to a different value.
 *
 * @param {boolean | string | number | null} value - The value to monitor for changes.
 * @param {Function} callback - The function to call when the value changes.
 *
 * @example
 * useValueChange(myValue, () => {
 *   console.log('Value has changed!');
 * });
 */
function useValueChange(value: boolean | string | number | null, callback: () => void) {
  const prevValue = useRef<boolean | string | number | null>(null);
  const [isInit, setIsInit] = useState(false);
  useEffect(() => {
    prevValue.current = value;
    setIsInit(true);
  }, []);

  useEffect(() => {
    if (isInit && value !== prevValue.current) {
      prevValue.current = value;
      callback();
    }
  }, [value]);
}

export default useValueChange;
