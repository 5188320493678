import { AuthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { LandingPage } from "./LandingPage";

const MainContent = () => {
  return (
    <>
      <div>
        <AuthenticatedTemplate>
          <Navigate to="/home" />
        </AuthenticatedTemplate>
      </div>
    </>
  );
};

export default function LandingRouter() {
  return (
    <LandingPage>
      <MainContent />
    </LandingPage>
  );
}
