import React, { useEffect } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Calendar } from "primereact/calendar";

function CalendarComponent(props: any) {
  const { name, label, ...rest } = props;
  const { setFieldTouched, isSubmitting, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!isSubmitting) return;
    setFieldTouched(name, true, true);
  }, [isSubmitting]);
  return (
    <>
      {label && <label htmlFor={name}> {label}</label>}
      <Field
        alt={name}
        name={name}
        {...rest}
        as={Calendar}
        className="calendar-style"
        dateFormat="dd/mm/yy"
        onChange={(e: any) => {
          if (e.value != null) {
            const date = new Date(e.value);
            const utctDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
            setFieldValue(name, utctDate);
          } else {
            setFieldValue(name, null);
          }
        }}
      />
      <ErrorMessage name={name} render={(msg) => <div className="error">{msg}</div>} />
    </>
  );
}
export default CalendarComponent;
