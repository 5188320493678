import * as Yup from "yup";
import { FormGroup } from "../../components/form";

export const applicationTypeForm: FormGroup = {
  type: "formGroup",
  fields: [
    {
      type: "field",
      key: "typeOfApplication",
      fieldType: "dropdown",
      label: "Type of Application",
      props: {
        placeholder: "Select an application type",
        options: [
          {
            label: "Disability only",
            value: 1,
          },
          {
            label: "Disability with Complex Medical Needs",
            value: 2,
          },
          {
            label: "Complex Medical Needs only",
            value: 3,
          },
        ],
      },
      validation: Yup.string().required("A response is required"), // Basic required validation
    },
  ],
};
