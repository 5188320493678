import React, { useEffect, useState } from "react";
import { apiClient } from "../../shared/customHooks/hooks/useApiClient";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { AxiosResponse } from "axios";
import { Button } from "primereact/button";
import { FormGroup } from "./models/models";
import FormCreatorComponent, { getInitalValuesAndValidationSchema } from "./components/FormCreatorComponent";
import { FormContext } from "./context/formContext";
import { ToastInfo } from "../toaster/toaster";
import { eventEmitter } from "../../shared/event";

interface FormProps {
  id?: string | null;
  apiClient: apiClient;
  formBody: FormGroup;
  formContext?: any;
  formTitle?: string;
  submitRoute?: string;
  saveSuccessCB?: (data?: any) => void;
  saveErrorCB?: (error?: any) => void;
  disabled?: boolean;
  getInitialValues?: (data: any) => void;
  cancelPath?: string;
}

const FormContainer: React.FC<FormProps> = ({
  id,
  apiClient,
  formBody,
  formContext,
  submitRoute,
  saveSuccessCB,
  saveErrorCB,
  formTitle,
  disabled = false,
  getInitialValues,
  cancelPath,
}) => {
  const [validation, setValidation] = useState(Yup.object().shape({}));
  const [initForm, setInitForm] = useState(false);

  const [formContextStore, setFormContextStore] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const [model, yup] = getInitalValuesAndValidationSchema(formBody);

    formik.setValues(model);
    setValidation(yup);
    if (id) {
      apiClient
        .fetchData(id)
        .then((res) => {
          res.data.dateOfBirth = new Date(res.data.dateOfBirth);
          formik.setValues({ ...model, ...(res.data ?? {}) });
          getInitialValues?.(res.data);
        })
        .catch(() => {})
        .finally(() => {
          setInitForm(true);
        });
    } else {
      setInitForm(true);
      getInitialValues?.({});
    }
  }, [id]);

  useEffect(() => {
    setFormContextStore({ ...formContext, disabled });
  }, [formContext]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) {
        setSubmitting(false);
        return;
      }
      const saveType = id == null ? "Submission" : "Update";
      try {
        const res: AxiosResponse<any> = id == null ? await apiClient.postData(values) : await apiClient.putData(values, id);
        saveSuccessCB?.(res.data);
        const data: ToastInfo = { title: "Success", message: saveType + " Successful!", severity: "success" };
        eventEmitter.emit("tstr", data);
        if (submitRoute != null) {
          navigate(submitRoute);
        }
      } catch (error: any) {
        if (error.response.data.validationResults) {
          error.response.data.validationResults.forEach((err: any) => {
            const data: ToastInfo = { title: "Error", message: err.errorMessage, severity: "error" };
            eventEmitter.emit("tstr", data);
          });
        }
        if (error.response.data.message) {
          const data: ToastInfo = { title: "Error", message: error.response.data.message, severity: "error" };
          eventEmitter.emit("tstr", data);
        }
        saveErrorCB?.(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  function checkAndScollErrors() {
    formik.validateForm().then(() => {
      if (!formik.isValid) {
        //need to render errors
        setTimeout(() => {
          const firstError = document.getElementsByClassName("error")[0] as HTMLElement;
          firstError.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 200);
      }
    });
  }

  return (
    <div className="w-full">
      <div className="text-left home-text">
        <form className="" onSubmit={formik.handleSubmit}>
          {formTitle && <h1>{formTitle}</h1>}
          <div className="">
            {initForm && (
              <FormikProvider value={formik}>
                <FormContext.Provider value={{ formContextStore, setFormContextStore, validation, setValidation }}>
                  <FormCreatorComponent formBody={formBody} />
                </FormContext.Provider>
              </FormikProvider>
            )}
            {!disabled && (
              <>
                <Button
                  className={id == null ? "submit-button" : "save-button"}
                  type="submit"
                  disabled={formik.isSubmitting}
                  label={id === "" || id == null ? "Submit" : "Save"}
                  onClick={() => {
                    formik.setFieldValue("actionType", "submit");
                    checkAndScollErrors();
                  }}
                />
                {cancelPath != null && (
                  <Button role="button" className="cancel-button ml-3" onClick={() => navigate(cancelPath ?? "")} label="Cancel" />
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormContainer;
