import React from "react";
import { PageHeader } from "../components/header/PageHeader";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Header } from "../components/header/Header";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { useApiClient } from "../shared/customHooks";

const InviteUserMasterComponent = () => {
  const { postData } = useApiClient("UserManagement/InviteUser", { identity: true });
  const headerText = [
    {
      class: "p-0 m-0 flex align-center",
      value: "Invite Users",
    },
  ];

  function InviteUser(data: any) {
    data.inviteRedirectUrl = import.meta.env.VITE_POSTLOGOUTREDIRECTURI;
    data.authorizationLevel = castValue(data.authorizationLevel);
    postData(data).then(() => {});
  }

  const castValue = (prop: string) => {
    return parseInt(prop);
  };

  return (
    <>
      <Header />
      <PageHeader headerText={headerText} />
      <div className="text-left home-text">
        <Formik
          initialValues={{ emailAddress: "", role: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.emailAddress) {
              errors.emailAddress = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
              errors.emailAddress = "Invalid email address";
            }

            if (values.role == null || parseInt(values.role) <= 0) {
              errors.role = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            InviteUser(values);
            setSubmitting(false);
          }}>
          {({ isSubmitting }) => (
            <Form>
              <label htmlFor="emailAddress">Email </label>
              <Field type="email" name="emailAddress" />
              <ErrorMessage name="emailAddress" component="div" />
              <br />
              <label htmlFor="role">Role </label>
              <Field name="role" defaultValue={""} as="select">
                <option value="" disabled hidden></option>
                <option value="1">Root</option>
                <option value="2">Admin</option>
                <option value="3">User</option>
                <option value="4">Dept</option>
              </Field>
              <ErrorMessage name="role" component="div" />
              <br />

              <ErrorMessage name="authorizationLevel" component="div" />
              <br />
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export const InviteUserMaster = () => {
  return (
    <AuthenticatedTemplate>
      <InviteUserMasterComponent />
    </AuthenticatedTemplate>
  );
};
