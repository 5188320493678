import { ReactElement } from "react";
import { routes } from "../../shared/routes";
import useBreadcrumbs, { BreadcrumbMatch } from "use-react-router-breadcrumbs";
import React from "react";
import { Link } from "react-router-dom";
import "../../styles/style.scss";

export interface BreadcrumbData<ParamKey extends string = string> {
  match: BreadcrumbMatch<ParamKey>;
  location: Location;
  key: string;
  breadcrumb: React.ReactNode;
}

export default function Breadcrumbs(): ReactElement | null {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <nav>
      <ul className="breadcrumb m-0">
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <li className="breadcrumb-item" key={match.pathname}>
            <Link to={match.pathname}>{breadcrumb}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
