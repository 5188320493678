import "primeicons/primeicons.css";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../breadCrumbs/BreadCrumb";

export function PageHeader({ headerText, hideBreadcrumbs = false }: { headerText: any[]; hideBreadcrumbs?: boolean; icon?: string }) {
  const [shadow, setshadow] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setshadow(window.scrollY > 200));
    }
  }, []);
  return (
    <>
      {" "}
      <div className={`header ${shadow ? "shadow" : ""} page-stick-header text-black bg-grey`}>
        <div className="flex justify-content-between align-items-center ">
          {" "}
          <div>
            {" "}
            {headerText.map((headText, index) => (
              <div key={index} className="flex align-items-center">
                {headText.icon && <span className={headText.icon}></span>}
                <h2 key={index} className={headText.class}>
                  {" "}
                  {headText.value}
                </h2>
              </div>
            ))}{" "}
          </div>
          <div> {!hideBreadcrumbs && <BreadCrumb />}</div>
        </div>
      </div>
    </>
  );
}
