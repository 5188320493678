import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useValueChange } from "../../shared/customHooks";

interface ModalProps {
  confirmAction: () => void;
  cancelAction?: () => void;
  bodyText?: string;
  headerLabel?: string;
  confirmLabel?: string;
  rejectLabel?: string;
}
export type ModalRef = {
  toggle: () => void;
};

export const Modal = forwardRef<ModalRef, ModalProps>(
  ({ confirmAction, cancelAction, bodyText, headerLabel = "Warning", confirmLabel = "I Agree", rejectLabel = "Cancel" }, ref) => {
    const [visible, setVisible] = useState(false);

    const toggle = () => {
      setVisible(!visible);
    };

    useImperativeHandle(ref, () => ({
      toggle,
    }));

    useValueChange(visible, () => {
      if (!visible) cancelAction?.();
    });

    return (
      <>
        {visible && (
          <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={
              <div className="flex items-center justify-content-between p-2 border-round-top">
                <div className="flex align-items-center">
                  <i className="pi pi-exclamation-triangle mr-2" />
                  <span>{headerLabel}</span>
                </div>
              </div>
            }
            footer={
              <div className="flex justify-content-center gap-2">
                <Button label={confirmLabel} onClick={confirmAction} autoFocus className="outline-button" />
                <Button
                  label={rejectLabel}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="outline-button-red"
                />
              </div>
            }>
            <div className="p-4 text-center">
              <p className="text-left">{bodyText}</p>
            </div>
          </Dialog>
        )}
      </>
    );
  }
);
Modal.displayName = "Modal";
