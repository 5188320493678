import { Menubar } from "primereact/menubar";
import "primeicons/primeicons.css";
import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { MenuItem } from "primereact/menuitem";

export function Header() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const Logout = async () => {
    try {
      await instance.logoutRedirect();
    } catch (error) {
      console.error(error);
    }
  };

  const items: MenuItem[] = [
    {
      label: "Home",
      url: "home",
    },
    {
      label: "Applications",
      url: "applications",
    },
    {
      label: account?.name,
    },
    {
      label: "Logout",
      command: () => {
        Logout();
      },
    },
  ];

  const isActive = (url: string) => {
    return location.pathname === "/" + url;
  };

  return (
    <>
      <div className="main-nav">
        <div className="flex justify-content-left kis-logo">
          <div className="flex text-blue font-bold justify-content-start text-left align-items-center flex-row">
            <div className="flex mt-1">
              <div className="header">
                <div className="text-purple text-3xl flex align-center ">
                  {" "}
                  <span className="kis-icon-purple"></span>Kindergarten Inclusion <br />
                  Support
                </div>
              </div>
            </div>
          </div>{" "}
        </div>

        <Menubar
          model={items.map((item) => ({
            ...item,
            className: isActive(item.url ?? "") ? "active-menu-item" : "", // Apply 'active' class if URL matches
          }))}
          className="mr-5 ml-1 bg-none border-none user-name pl-0 flex justify-content-end drop-down"
        />
      </div>
    </>
  );
}
