import { useCallback } from "react";
import axios, { AxiosResponse } from "axios";
import { BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import { eventEmitter } from "../../event";

interface clientOptions {
  requireAuth?: boolean;
  identity?: boolean;
}

function useApiClient(controller: string, { requireAuth = true, identity = false }: clientOptions = {}): apiClient {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const routeUrlApi = `${import.meta.env.VITE_API_KEY}/api/${controller}`;
  const routeUrlIdentity = `${import.meta.env.VITE_IDENTITY}/api/${controller}`;

  const getAccessToken = useCallback(async () => {
    if (!account) {
      throw new Error("No account available");
    }
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: account,
    };
    try {
      await instance.initialize();
      const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
        if (account && inProgress === "none") {
          const tokenResponse = await instance.acquireTokenPopup({
            scopes: loginRequest.scopes,
          });
          return tokenResponse.accessToken;
        }
      }
      throw error;
    }
  }, [instance, account, inProgress]);

  const handleCall = useCallback(async (apiCall: Promise<AxiosResponse<any, any>>): Promise<AxiosResponse<any, any>> => {
    try {
      eventEmitter.emit("loading", { isLoading: true });
      const response = await apiCall;
      return response;
    } finally {
      eventEmitter.emit("loading", { isLoading: false });
    }
  }, []);

  const fetchData = useCallback(
    async (route?: string, params?: any): Promise<AxiosResponse<any, any>> => {
      const apiEndpoint = (identity ? routeUrlIdentity : routeUrlApi) + (route ? `/${route}` : "");

      let headers = {};
      if (requireAuth) {
        const token = await getAccessToken();
        headers = { Authorization: `Bearer ${token}` };
      }
      return handleCall(axios.get(apiEndpoint, { headers, params }));
    },
    [routeUrlApi, getAccessToken, handleCall]
  );

  const postData = useCallback(
    async (data: any, route?: string): Promise<AxiosResponse<any, any>> => {
      const apiEndpoint = (identity ? routeUrlIdentity : routeUrlApi) + (route ? `/${route}` : "");
      let headers = {};
      if (requireAuth) {
        const token = await getAccessToken();
        headers = { Authorization: `Bearer ${token}` };
      }
      return handleCall(
        axios.post(apiEndpoint, data, { headers }).then((res) => {
          return res;
        })
      );
    },
    [routeUrlApi, getAccessToken, handleCall]
  );

  const putData = useCallback(
    async (data: any, route?: string): Promise<AxiosResponse<any, any>> => {
      const apiEndpoint = (identity ? routeUrlIdentity : routeUrlApi) + (route ? `/${route}` : "");
      let headers = {};
      if (requireAuth) {
        const token = await getAccessToken();
        headers = { Authorization: `Bearer ${token}` };
      }
      return handleCall(
        axios.put(apiEndpoint, data, { headers }).then((res) => {
          return res;
        })
      );
    },
    [routeUrlApi, getAccessToken, handleCall]
  );

  return {
    fetchData,
    postData,
    putData,
  };
}

export interface apiClient {
  fetchData: (route?: string, params?: any) => Promise<AxiosResponse<any, any>>;
  postData: (data: any, route?: string) => Promise<AxiosResponse<any, any>>;
  putData: (data: any, route?: string) => Promise<AxiosResponse<any, any>>;
}
export default useApiClient;
