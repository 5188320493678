import * as Yup from "yup";
import { FormGroup } from "../../components/form";

export const childForm: FormGroup = {
  type: "formGroup",
  fields: [
    {
      type: "field",
      key: "firstName",
      fieldType: "input",
      label: "First Name",
      props: {},
      validation: Yup.string().required("A response is required"), // Basic required validation
    },
    {
      type: "field",
      key: "lastName",
      fieldType: "input",
      label: "Last Name",
    },
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "fieldadv",
      },
      label: "Please ensure that this field is only left blank if the child does not have a surname",
    },
    {
      type: "field",
      key: "dateOfBirth",
      fieldType: "calendar",
      label: "Date of Birth",
      validation: Yup.date().required("A response is required").max(new Date(), "Date may not be a future date"),
    },
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "warning",
      },
      label:
        "If the child is over six years old, please ensure that if a school exemption is required that it has been submitted to the DE regional office. This application cannot be assessed until this has occurred",
    },
    {
      type: "field",
      key: "gender",
      fieldType: "dropdown",
      label: "Gender",
      props: {
        placeholder: "Select a Gender",
        options: [
          {
            label: "Female",
            value: 1,
          },
          {
            label: "Male",
            value: 2,
          },
          {
            label: "Other",
            value: 3,
          },
        ],
      },
      validation: Yup.string().required("A response is required"),
    },
  ],
};
