import React, { useEffect, useState } from "react";
import { useApiClient } from "../../shared/customHooks";
import FormContainer from "../form/FormContainer";
import ComponentHeader, { ComponentHeadeProps } from "../componentHeader/componentHeader";
import { applicationTypeForm } from "../../shared/formObjects/applicationTypeForm";

function ApplicationTypeComponent(props: any) {
  const api = useApiClient("ApplicationType");
  const [formContext] = useState({ requireAuth: false });
  const [success, setSuccess] = useState(false);
  const [appId, SetAppId] = useState("");

  const [headerData, setHeaderData] = useState<ComponentHeadeProps>({});

  function getHeaderData(data: any) {
    const headerData: ComponentHeadeProps = {
      dateCreated: data?.dateCreated,
      userCreated: data?.userCreated,
      showEdit: props.disabled,
      editPath: `/applicationtype?id=${appId}`,
      showBack: !props.disabled,
      backPath: `/viewapplication?id=${appId}`,
    };
    setHeaderData(headerData);
  }

  useEffect(() => {
    SetAppId(props?.id);
  }, [success, appId, props.id]);

  const handleSUccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <ComponentHeader {...headerData} label="Type of Application" />
      <FormContainer
        id={appId}
        apiClient={api}
        formBody={applicationTypeForm}
        formTitle=""
        formContext={formContext}
        saveSuccessCB={handleSUccess}
        getInitialValues={getHeaderData}
        disabled={props.disabled}
        cancelPath={`/viewapplication?id=${appId}`}
        submitRoute={`/viewapplication?id=${appId}`}
      />
    </>
  );
}

export default ApplicationTypeComponent;
