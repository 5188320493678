import { useMsal } from "@azure/msal-react";
import { Button } from "primereact/button";
import React from "react";

export const AccessDenied = () => {
  const { instance, accounts } = useMsal();

  const Logout = async () => {
    accounts;
    try {
      await instance.logoutRedirect();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="triangle-image">
        <img src="https://stkisapikappaae4symbg.blob.core.windows.net/loginlayout/triangle-img.png" alt="Background Image" />
      </div>
      <div className="w-full absolute top-0 bottom-0 left-0 right-0 m-auto flex align-items-center justify-content-center h-auto flex-column">
        <div className="bg-grey w-1 logout-img">
          {" "}
          <img src="/assets/icons/unauth.svg" />
        </div>
        <strong className="my-5 text-purple">Sorry, you are not authorized to access this page.</strong>
        <Button className="border-0 bg-transparent" label="Logout" onClick={Logout}>
          {" "}
        </Button>
        <div className="text-purple font-bold"></div>
      </div>
    </>
  );
};
