import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { eventEmitter } from "../../shared/event";

eventEmitter;

export function ToasterComponent() {
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const listener = (info: ToastInfo) => {
      drawToaster(info);
    };

    eventEmitter.addListener("tstr", listener);

    return () => {
      eventEmitter.removeListener("tstr", listener);
    };
  }, []);

  function drawToaster({ severity, title, message }: ToastInfo) {
    toast.current?.show({ severity, summary: title, detail: message, life: 3000 });
  }

  return (
    <>
      <Toast ref={toast} className="pointer-events-none" />
    </>
  );
}

export interface ToastInfo {
  title: string;
  message: string;
  severity: "info" | "success" | "warn" | "error" | "secondary" | "contrast" | undefined;
}
