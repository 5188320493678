import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

function DataTableComponent(props: any) {
  const { name, columns, data, ...rest } = props;

  const cellEditor = (options: any) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  return (
    <div className="flex flex-column w-full dataTable">
      <DataTable alt={name} name={name} {...rest} value={data}>
        {columns.map((col: any) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode={col.filterMatchMode}
            filterPlaceholder={col.filterPlaceholder}
            editor={col.editable && ((options) => cellEditor(options))}
          />
        ))}
      </DataTable>
    </div>
  );
}

export default DataTableComponent;
