import React from "react";

const ExternalFormsHeader = () => {
  return (
    <>
      <div className="flex align-items-center justify-content-between w-full text-purple bg-white m-0 p-3 header border-bottom-3 border-greys">
        <h1 className="">Kindergarten Inclusion Support</h1> <div className="vic-logo"></div>
      </div>
    </>
  );
};

export default ExternalFormsHeader;
