import React, { useEffect } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const HomeContent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/applications");
  }, []);

  return <></>;
};

export const Home = () => {
  return (
    <AuthenticatedTemplate>
      <HomeContent />
    </AuthenticatedTemplate>
  );
};
